<template>
    <div v-if="userIsUnsubscribed" class="flex flex-row text-center mx-auto">
        <button class="bg-gray-200 border-none cursor-pointer w-full min-h-[180px] p-0">
            <img class="inset-0 max-h-[300px] w-full object-cover" src="../../assets/banners/Mardi-Gras-2025.png" alt="Picture of a Mardi Gras masque" @click="(e) => handleBannerClick(e)">
            <p v-show=false class="text-gray-700 bg-gray-200 text-lg italic m-0 p-0" @click="(e) => handleOfferClick(e)">Charity Quest is 100% free for local charities because of our generous sponsors. Please support them!</p>
            <p v-show=false class="text-gray-700 bg-gray-200 text-lg italic m-0 p-0" @click="(e) => handleSubscriptionClick(e)">If you'd like to take a break from seeing banner advertisements, please subscribe to Charity Quest.</p>
            <!-- Or you could subscribe... "If you'd like to take a break from seeing banner advertisements, please become"-->
        </button>
    </div>
  </template>

<script>
export default {
    name: 'FDFullWidthBanner',
    methods: {
        handleBannerClick ( e )
        { 
            console.log("Clicked the banner!");
            window.open("https://www.eventbrite.com/e/celebrate-mardi-gras-with-charity-quest-tickets-1222307357209?aff=oddtdtcreator", '_blank', 'noreferrer');

            var tagsObj = {
                messageType: "Chariteer Clicks Sponsor's Banner In Charity List"
            }
            this.$store.dispatch('clickTrackLogForAmazon', tagsObj)
        },
        handleOfferClick ( e )
        { 
            console.log("Clicked the offer!");
            window.open("https://3475f3mlut1frf6jqkwieltcx9.hop.clickbank.net/?tid=charity_quest_website", '_blank', 'noreferrer');
        
            var tagsObj = {
                messageType: "Chariteer Clicks Sponsor's Offer"
            }
            this.$store.dispatch('clickTrackLogForAmazon', tagsObj)
        },
        handleSubscriptionClick ( e )
        { 
            console.log("Clicked soscriyeb!");
            this.$router.push('/subscribe')
        }
    },
    computed: {
        userIsUnsubscribed() {
            let status = this.$store.state.playerProfile?.chariteer?.subscriptionStatus;
            console.log("Subscription Level: " + status);

            if ( status == "unsubscribed" )
                return true;
            else
                return false;
        }
    }
}
</script>