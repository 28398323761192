<template>
  <div>
    <!-- Page Container was here -->
    <div>
      <TopNav />
    </div>

    <div class="content-wrap bg-cq-orange-light py-10">
      <div v-if="loading">
        Loading!
      </div>
      <div v-else class="max-w-xl mx-auto">
        <router-link
          class="bg-gray-100 hover:bg-cq-orange text-gray-800 font-bold no-underline py-2 px-4 mr-3 mb-5 rounded border-none inline-flex items-center"
          :to="questListUrl"
        >
          <img
            class="w-4 h-4 mr-2"
            alt="The left arrow symbol"
            :src="LeftArrowIcon"
          />
          <span>Back to the list of Quests</span>
        </router-link>

        <!-- TO DO -->
        <!-- This View should take into consideration who the user is. Regular volunteer, or Management Team? -->

        <!-- Quest Title with Medallion -->
        <!-- TO DO: Title must not wrap -->
        <div
          v-if="databaseQuest && databaseQuest.quest"
          class="z-20 px-6 flex flex-row items-center rounded-t-lg"
          :style="{ backgroundColor: getHexForMedallion }"
        >
          <h1 class="text-white drop-shadow-md">
            {{ databaseQuest.quest.eventTitle }}
          </h1>

          <div class="ml-auto mt-2">
            <IconPicker :name="firstServiceTag" class="w-28" size="medium" />
          </div>
        </div>

        <!-- Notifications -->
        <template v-if="databaseQuest && databaseQuest.quest">
          <!-- If we're in the Management Group... -->
          <div
            v-if="chariteerIsManager"
            class="text-left bg-gray-400 text-white"
          >
            <img
              class="w-4 h-4 mr-2 pl-2"
              alt="Something, clipboard?"
              src="../assets/navicons/CQ-Navicon_Activity.png"
            />
            <span
              >You're one of the
              {{ this.managedProviderGroupDataObject?.Roles[0].RoleName }} of
              {{ databaseQuest.quest.provider.name }}.</span
            >
            <div class="flex flex-row">
              <!-- EDITING QUESTS -->
              <div
                v-if="!questIsOver && chariteerCanEditQuests"
                class="flex flex-row-reverse"
              >
                <button
                  class="bg-gray-200 hover:bg-cq-orange text-gray-800 font-bold py-2 px-4 m-1 rounded border-none inline-flex items-center"
                  v-on:click="handleOpenEditQuestModal"
                >
                  <img
                    class="w-4 h-4 mr-2"
                    alt="A little pencil, meant for editing the Quest."
                    src="../assets/navicons/CQ-Navicon_Pencil.png"
                  />
                  <span>Edit Quest Details</span>
                </button>
              </div>
              <!-- SENDING ALERTS -->
              <div
                v-if="!questIsOver && chariteerCanSendQuestAlerts"
                class="flex flex-row-reverse"
              >
                <button
                  class="bg-gray-200 hover:bg-cq-orange text-gray-800 font-bold py-2 px-4 m-1 rounded border-none inline-flex items-center"
                  v-on:click="handleOpenEditChariteerAlertModal"
                >
                  <img
                    class="w-4 h-4 mr-2"
                    alt="A little pencil, meant for editing the Quest."
                    src="../assets/navicons/CQ-Navicon_Pencil.png"
                  />
                  <span>Recruit More Volunteers</span>
                </button>
              </div>
            </div>
          </div>
          <!-- If we're on this Quest... -->
          <div
            v-if="chariteerIsOnThisQuest"
            class="text-left bg-green-400 text-white"
          >
            <img
              class="w-4 h-4 mr-2 pl-2"
              alt="A green checkmark"
              src="../assets/CQ-Butticon_Present.png"
            />
            <span>You're signed up for this Quest!</span>
          </div>
          <!-- If this Quest has been claimed by a Group we belong to -->
          <div
            v-if="questIsClaimedByYourGroup"
            class="text-left bg-green-400 text-white"
          >
            <img
              class="w-4 h-4 mr-2 pl-2"
              alt="A green checkmark"
              src="../assets/CQ-Butticon_Present.png"
            />
            <span
              >Your group {{ raidingGuildName }} has reserved this Quest!</span
            >
          </div>
          <!-- If this Quest has been claimed by our bitter rivals... -->
          <div
            v-if="questIsClaimedByRivalGroup"
            class="text-left bg-red-500 text-white"
          >
            <!-- <img class="fill-current w-4 h-4 mr-2 pl-2" alt="The X symbol, because the Quest has been claimed by another Group" src="../assets/CQ-Butticon_Absent.png" /> -->
            <p class="m-0 px-5">
              This Quest is reserved for volunteers from
              <span
                ><b>{{ raidingGuildName }}</b></span
              >. If that's your Guild, please scroll to the bottom and enter
              your Guild code to be added to your Group before you sign up.
            </p>
          </div>

          <div v-if="questIsOver" class="text-left bg-red-500 text-white">
            <img
              class="w-4 h-4 mr-2 pl-2"
              alt="The X symbol, because the Quest is over"
              src="../assets/CQ-Butticon_Absent.png"
            />
            <span>This Quest is over.</span>
          </div>
        </template>

        <!-- "THE WHITE" -->
        <div
          v-if="databaseQuest && databaseQuest.quest"
          class="bg-white p-6 rounded-b-lg"
        >
          <!-- Charity Name and Quest Category Flag -->
          <h2 class="m-0 pb-5">
            {{ databaseQuest.quest.provider.name }}
          </h2>

          <!-- Date Bubble -->
          <div
            v-if="databaseQuest?.quest?.startedAt != null"
            class="bg-gray-100 flex items-center rounded-lg py-1 text-lg"
          >
            <img
              class="w-20 h-20 m-3"
              alt="Clock"
              src="../assets/navicons/CQ-Navicon_Stopwatch.png"
            />
            <div>
              <p class="mx-0 mt-0 mb-4">
                <b>{{ questDayDisplay }}</b>
              </p>
              <!-- Spread the times out nicely -->
              <div class="grid grid-cols-2 space-x-5">
                <p class="m-0">
                  {{ "Start: " + questStartTimeDisplay }}
                </p>
                <p v-if="databaseQuest.quest.endedAt != null" class="m-0">
                  {{ "End: " + questEndTimeDisplay }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="databaseQuest?.quest?.eventType == 'HYBRID-ROLE'"
            class="bg-gray-100 flex flex items-center rounded-lg py-1 text-lg"
          >
            <img
              class="w-20 h-20 m-3"
              alt="Clock"
              src="../assets/navicons/CQ-Navicon_Stopwatch.png"
            />
            <div class="my-2">
              <p class="m-0">
                <b>Flexible Hybrid Role</b>
              </p>
              <div>
                <p class="m-0 mr-5">
                  This role will require a blend of on-site volunteering via
                  Charity Quest, and remote work that is not currently tracked
                  by Charity Quest.
                </p>
              </div>
            </div>
          </div>

          <!-- Line Break between Time & Location -->
          <br />

          <!-- Location Bubble -->
          <!-- TO DO: Map Pin Icon on the left -->
          <!-- TO DO: Cleaner spacing between lines here -->
          <!-- TO DO: Height match the bubble above -->
          <div
            v-if="databaseQuest.quest.location != undefined"
            class="bg-gray-100 flex items-center rounded-lg py-1"
          >
            <img
              class="w-20 h-20 m-3"
              alt="Clock"
              src="../assets/navicons/CQ-Navicon_MapTick.png"
            />
            <div>
              <p class="m-0 text-lg">
                <b>{{ databaseQuest.quest.location.name }}</b>
              </p>
              <p class="m-0">
                {{ databaseQuest.quest.location.address }}
              </p>
              <p class="m-0">
                {{ databaseQuest.quest.location.detail }}
              </p>
            </div>
          </div>
          <div v-else class="bg-gray-100 rounded-lg">
            <h2>{{ "Location undefined" }}</h2>
          </div>

          <br />

          <!-- Quest Description (allows for Markdown) -->
          <div v-html="questDescription" class="questDescription"></div>
          <div
            v-if="
              databaseQuest.quest.giverName != undefined &&
                databaseQuest.quest.giverName != '' &&
                databaseQuest?.quest?.eventType != 'HYBRID-ROLE'
            "
          >
            <p>
              Report to
              <span
                ><b>{{ databaseQuest.quest.giverName }}</b></span
              >
              when you arrive.
            </p>
          </div>

          <br />

          <!-- Requirements -->
          <h3>Requirements:</h3>
          <!-- Age Policy -->
          <div
            v-if="
              questAgePolicyObject != null &&
                databaseQuest?.quest?.eventType != 'HYBRID-ROLE'
            "
            class="m-0"
          >
            <p class="m-0">
              You must be at least
              <span
                ><b>{{ questSoloAge }}</b></span
              >
              to volunteer for this Quest.
            </p>
            <p v-if="questAdultSupervisionPolicyText != null">
              {{ questAdultSupervisionPolicyText }}
            </p>
          </div>
          <!-- Orientations -->
          <div>
            <ul v-if="false" class="m-0 p-0">
              <!-- Change display whether these are Completed or Not in your profile. -->
              <li
                class="list-none m-0"
                v-for="requiredOrientation in databaseQuest?.quest
                  ?.requiredOrientations"
                v-bind:key="requiredOrientation.id"
              >
                Required Orientation:
                <span class="text-red-500">{{ requiredOrientation.name }}</span>
              </li>
              <li class="list-none m-0">
                Orientation Length: {{ theSiftedOrientationArray.length }}
              </li>
              <!--
              <li class="list-none m-0">
                Orientations: {{ theSiftedOrientationArray }}
              </li>
              -->
            </ul>
          </div>

          <br />

          <!-- Raiding Guilds, if any -->
          <div v-if="databaseQuest != undefined">
            <div
              v-if="raidingGuildContract != null"
              class="bg-gray-100 mx-5 my-2 px-2 py-2 rounded border-4 border-opacity-100 border-black"
            >
              <p class="text-center font-black m-0 mt-2 p-0">
                {{ raidingGuildContract.guild.name }}
              </p>
              <p class="text-center italic m-0 p-0">
                {{ raidingGuildContract.guild.description }}
              </p>
              <p class="text-center">
                {{
                  raidingGuildContract.chariteer.nameFirst +
                    " " +
                    raidingGuildContract.chariteer.nameLast
                }}
                reserved this Quest on behalf of
                <span
                  ><b>{{ raidingGuildContract.guild.name }}</b></span
                >
              </p>

              <!-- Sensitive Point of Contact info + Easy Join Code is for Managers only -->
              <template v-if="chariteerIsManager">
                <p class="text-center">
                  {{
                    "Email Address: " +
                      raidingGuildContract.chariteer.emailAddress
                  }}
                </p>
                <p class="text-center">
                  {{
                    "Phone Number: (" +
                      raidingGuildContract.chariteer.phoneNumber.substring(
                        0,
                        3
                      ) +
                      ") " +
                      raidingGuildContract.chariteer.phoneNumber.substring(
                        3,
                        6
                      ) +
                      "-" +
                      raidingGuildContract.chariteer.phoneNumber.substring(
                        6,
                        10
                      )
                  }}
                </p>
                <div class="text-center bg-black text-white rounded m-1 p-2">
                  <p class="m-0 p-0">
                    Easy Join Code:
                    {{ raidingGuildContract.guild.easyJoinCode }}
                  </p>
                  <p class="italic text-sm m-0 p-0">
                    This access code is only for verified group members. Please
                    only show this code to volunteers who need to sign up for
                    their group's event.
                  </p>
                  <!-- Should we show Guild Leaders here? Zena Aldabagh is probably an Admin of her family group. She's also a point of contact... problem solved? -->
                </div>
              </template>
            </div>
            <br />
          </div>

          <!-- FEAST FAQ -->
          <div v-if="this.databaseQuest.quest.eventType == 'SUPPLY-REQUEST'">
            <h3 class="text-center mt-0 mb-0">Frequently Asked Questions</h3>
            <h4 class="mt-0 mb-0">
              How many people do you typically serve at breakfast?
            </h4>
            <p class="mt-0 mb-0">
              We average about 60 people every day for breakfast.
            </p>
            <h4 class="mt-0 mb-0">What time is breakfast served?</h4>
            <p class="mt-0 mb-0">
              Breakfast is served promptly to our guests at 9:00 am.
            </p>
            <h4 class="mt-0 mb-0">
              How many people do you typically serve at lunch?
            </h4>
            <p class="mt-0 mb-0">
              We average about 100 people every day for lunch.
            </p>
            <h4 class="mt-0 mb-0">What time is lunch served?</h4>
            <p class="mt-0 mb-0">
              The firt seating of lunch is served promptly to our guests at
              noon. There is a second seating afterward that begins at 1:30 pm.
            </p>
            <h4 class="mt-0 mb-0">
              How many people do you typically serve at dinner?
            </h4>
            <p class="mt-0 mb-0">
              We average about 100 people every day for dinner.
            </p>
            <h4 class="mt-0 mb-0">What time is dinner served?</h4>
            <p class="mt-0 mb-0">
              The first seating of dinner is served promptly to our guests at
              5:30 pm. There is a second seating afterward that begins at 7:00
              pm.
            </p>
            <h4 class="mt-0 mb-0">
              What kind of food do your guests enjoy the most?
            </h4>
            <p class="mt-0 mb-0">
              Please serve ac omplete feast that has fresh meat liek chicken or
              steak and fresh vegetables, as well as grains.
            </p>
            <h4 class="mt-0 mb-0">
              Do I have to provide dessert for everybody?
            </h4>
            <p class="mt-0 mb-0">
              You are more than welcome to bring dessert, but it is not
              required.
            </p>
            <h4 class="mt-0 mb-0">
              Do I have to provide drinks for everybody?
            </h4>
            <p class="mt-0 mb-0">
              You are more than welcome to bring non-alcoholic drinks, but it is
              not required. We will always serve water and anything else we
              have.
            </p>
            <h4 class="mt-0 mb-0">
              Can I cook the meal at your shelter?
            </h4>
            <p class="mt-0 mb-0">
              Yes, you are welcome to use our kitchen as long as you can arrive
              at least 1 hour earlier than listed, and if you coordinate your
              arrival with the Shelter Manager Rev. Anthony Forbes at (201)
              620-4792 after submitting this form.
            </p>
            <h4 class="mt-0 mb-0">
              Can I just bring food and warm it up in the kitchen?
            </h4>
            <p class="mt-0 mb-0">
              Absolutely! We have a large gas range, an oven, and a microwave
              you can use. As long as you arrive on time, you should be able to
              heat everything up before the meal is served.
            </p>
            <h4 class="mt-0 mb-0">
              If I don't bring enough food, will you have extra food for
              everyone?
            </h4>
            <p class="mt-0 mb-0">
              No. If the Kitchen Manager is expecting your donation, he or she
              will not prepare a meal and your donation will be the sole focus.
              Make sure to bring a feast!
            </p>
          </div>

          <!-- Volunteers -->
          <div v-if="databaseQuest != undefined" class="questDetailsFlexbox">
            <ul
              v-if="databaseQuest?.quest?.eventType != 'HYBRID-ROLE'"
              class="questDetailsVolunteerList"
            >
              <!-- Is it freaking out because there are 0 pledged contracts? that's important... -->
              <h3>
                Volunteers
                {{
                  "( " +
                    theSiftedContractArray.length +
                    " of " +
                    databaseQuest.quest.resourceMaxNumber +
                    " )"
                }}
              </h3>

              <li
                class="bg-gray-100 mx-4 my-2 px-2 py-2 rounded border-4 border-opacity-100 border-black"
                v-for="item in theSiftedContractArray"
                v-bind:class="{
                  questDetailsVolunteerListPlayer:
                    item.chariteerId ==
                    $store.state.playerProfile.masterPlayerID
                }"
                :key="item.id"
              >
                <!-- TO TRY: Put a computed array into this v-for instead of raw databaseQuest.quest.pledgedContracts -->
                <div
                  v-if="
                    item.abandonedAt == undefined &&
                      item.guildId == undefined &&
                      chariteerIsManager &&
                      chariteerCanReadNames
                  "
                >
                  {{ item.chariteer.nameFirst + " " + item.chariteer.nameLast }}
                  {{
                    chariteerCanReadContactInfo &&
                      `(${calculatedAgeFromDOB(item.chariteer.dateOfBirth)})`
                  }}
                  <br />
                  {{
                    chariteerCanReadContactInfo && item.chariteer.emailAddress
                  }}
                  <br />
                  {{
                    chariteerCanReadContactInfo &&
                      "(" +
                        item.chariteer.phoneNumber.substring(0, 3) +
                        ") " +
                        item.chariteer.phoneNumber.substring(3, 6) +
                        "-" +
                        item.chariteer.phoneNumber.substring(6, 10)
                  }}
                  <br />
                  <!-- The "Present" Button -->
                  <div v-if="chariteerCanTakeAttendance">
                    <button
                      v-if="checkNewAttendanceArray(item, 'Present')"
                      class="bg-green-400 hover:bg-green-300 text-black font-medium py-1 px-4 rounded border border-red-100 hover:border-transparent"
                    >
                      Present
                    </button>
                    <button
                      v-else-if="checkAttendance(item, 'Present')"
                      class="bg-green-500 hover:bg-green-400 text-black font-medium py-1 px-4 rounded border border-red-100 hover:border-transparent"
                    >
                      Present
                    </button>
                    <button
                      v-else
                      v-on:click="markAttendance(item, 'Present')"
                      class="bg-gray-200 hover:bg-green-400 text-black font-medium py-1 px-4 rounded border border-red-100 hover:border-transparent"
                    >
                      Present
                    </button>
                    <!-- The "Absent" Button -->
                    <button
                      v-if="checkNewAttendanceArray(item, 'Absent')"
                      class="bg-red-400 hover:bg-red-300 text-black font-medium py-1 px-4 rounded border border-green-100 hover:border-transparent"
                    >
                      Absent
                    </button>
                    <button
                      v-else-if="checkAttendance(item, 'Absent')"
                      class="bg-red-500 hover:bg-red-400 text-black font-medium py-1 px-4 rounded border border-green-100 hover:border-transparent"
                    >
                      Absent
                    </button>
                    <button
                      v-else
                      v-on:click="markAttendance(item, 'Absent')"
                      class="bg-gray-200 hover:bg-red-400 text-black font-medium py-1 px-4 rounded border border-red-100 hover:border-transparent"
                    >
                      Absent
                    </button>
                    <!-- The "Special" Button -->
                    <button
                      v-show="true"
                      v-on:click="
                        showMoreOptionsForSpecificChariteerModal(
                          item.chariteer,
                          e,
                          item
                        )
                      "
                      class="bg-gray-200 hover:bg-cq-orange text-black font-medium py-1 px-4 rounded border border-red-100 hover:border-transparent"
                    >
                      More...
                    </button>
                  </div>
                  <!-- TO DO -->
                </div>
                <div
                  v-else-if="
                    item.abandonedAt == undefined && item.guildId == undefined
                  "
                >
                  {{ item.chariteer.username + " " + flexArrivalString(item) }}
                  <!-- Call Computed functions that call utility functions so we can determine how to display this user -->
                </div>
                <div v-else-if="item.guildId == undefined">
                  {{ "BAILED: " + item.chariteerId }}
                </div>
              </li>
              <!-- Empty Slots? Well, empty slots... empty slots never change. -->
              <li
                class="questDetailsVolunteerListEmpty"
                v-for="n in slotsRemaining"
                v-bind:key="n"
              >
                Open Slot
              </li>
            </ul>

            <template
              v-if="
                chariteerIsManager &&
                  databaseQuest?.quest?.eventType != 'HYBRID-ROLE'
              "
            >
              <button
                class="bg-green-300 hover:bg-green-400 font-medium my-5 py-1 px-4 rounded border border-red-100 hover:border-transparent"
                v-bind:class="{
                  disabledSaveButtonStyle: providerHasSaveChanges == false
                }"
                v-on:click="saveAttendanceRecordsToDatabase"
              >
                Confirm and E-Verify Volunteer Attendance
              </button>
            </template>

            <!-- LOGIN FORK A: "LOG IN" User isn't logged in and it directs them to do that -->
            <template
              v-if="Object.keys($store.state.playerProfile).length === 0"
            >
              <p>
                Please log in or
                <a
                  class="underline hover:cursor-pointer"
                  v-on:click="goToSignUp"
                >
                  sign up</a
                >
                to volunteer for this Quest.
              </p>
              <button class="questDetails-logInButton" v-on:click="goToLogin">
                Log In
              </button>
            </template>

            <!-- LOGIN FORK B: User is logged in, test their eligibility to sign up -->
            <template v-else>
              <!-- OPTION 1, "QUEST OVER" (This Quest's end time is in the past.) -->
              <p v-if="questIsOver">This Quest ended already.</p>

              <!-- OPTION 2, "BAIL OUT" (You're on this Quest, regardless of whether it's full) --->
              <button
                class="questDetails-bailOutButton"
                v-else-if="chariteerIsOnThisQuest"
                v-on:click="bailOutOfQuest"
              >
                Bail out of this Quest...
              </button>

              <!-- OPTION 3, "QUEST RAIDED" (You're not in the Raiding Party) --->
              <template v-else-if="questIsClaimedByRivalGroup">
                <p class="text-center mt-10">
                  <span
                    ><b>{{ raidingGuildName }}</b></span
                  >
                  has claimed this Quest. To join this Guild, click the button
                  below and enter your Guild's secret password.
                </p>
                <button
                  class="bg-indigo-400 hover:bg-indigo-500 cursor-pointer text-white font-bold my-5 py-3 px-4 rounded border-none"
                  v-on:click="showGuildCodePanel"
                >
                  Enter your Guild code
                </button>
              </template>

              <!-- OPTION 4: "QUEST FULL" (No room for you on this Quest) --->
              <!-- TO DO: Join the waitlist! -->
              <p v-else-if="questIsFull">This Quest is full.</p>

              <!-- OPTION 5: "NOT EXPERIENCED ENOUGH" (Didn't meet the prerequisite hour minimum) --->
              <div
                v-else-if="
                  chariteerIsNotExperiencedEnoughWithThisCharity &&
                    !chariteerIsManager
                "
              >
                <!-- This box shows the user their missing hours-->
                <div
                  class="flex flex-row items-center bg-transparent rounded mt-5"
                >
                  <p class="px-5">
                    You have not volunteered enough hours with
                    <span
                      ><b>{{ databaseQuest.quest.provider.name }}</b></span
                    >
                    to sign up for this Quest. Please return when you have more
                    experience!
                  </p>
                  <ProgressCircle
                    :Radius="65"
                    :Percent="progressFillPercentage"
                    :Text="progressCircleText"
                    class="pr-5"
                  />
                </div>

                <!-- Added this box due to feedback from the St. Mary Advocates ladies -->
                <!-- Will we need this in the future? -->
                <div
                  v-if="
                    this.databaseQuest?.quest?.providerId == '7F6860372DB1C8E0'
                  "
                  class="flex flex-row bg-transparent m-0"
                >
                  <button
                    class="bg-indigo-400 hover:bg-indigo-500 cursor-pointer text-white font-bold my-5 py-3 px-4 w-full rounded border-none"
                    v-on:click="showGuildCodePanel"
                  >
                    Click here to enter your organization code instead
                  </button>
                </div>
              </div>

              <!-- OPTION 6: "ORIENTATION MISSING" (Missing at least 1 of the required Orientations) --->
              <div
                v-else-if="!chariteerIsFullyTrained && !chariteerIsManager"
                class="bg-transparent rounded"
              >
                <!-- Loop of all required orientations goes here -->
                <div>
                  <h4 class="text-center">
                    To sign up for this Quest, you must first complete the
                    following Training modules required by
                    {{ this.databaseQuest?.quest?.provider?.name }}.
                  </h4>
                  <!-- An Orientation Box-->
                  <div
                    v-for="training in this.databaseQuest?.quest
                      ?.requiredOrientations"
                    :key="training.id"
                    class="flex flex-row bg-gray-100 rounded-md shadow-md p-5 my-5"
                  >
                    <!-- The Left, Icon -->
                    <div class="content-top">
                      <!-- Complete -->
                      <div
                        v-if="
                          determineOrientationStatus(training) == 'Complete'
                        "
                        class="bg-green-500 w-20 rounded-md mt-2 mr-3 p-2 flex flex-col items-center"
                      >
                        <img
                          class="w-10 h-10"
                          alt="Check, because this Charity Quest user has the proper completed Training."
                          src="../assets/navicons/CQ-Navicon-Checkmark.png"
                        />
                        <p class="text-xs text-white font-medium m-0">
                          Complete!
                        </p>
                      </div>
                      <!-- Interested or Provisional -->
                      <div
                        v-else-if="
                          determineOrientationStatus(training) ==
                            'Interested' ||
                            determineOrientationStatus(training) ==
                              'Provisional'
                        "
                        class="bg-cq-orange w-20 rounded-md mt-2 mr-3 p-2 flex flex-col items-center"
                      >
                        <img
                          class="w-10 h-10"
                          alt="X, because this Charity Quest user does not have the proper completed Training."
                          src="../assets/navicons/CQ-Navicon-Hourglass.png"
                        />
                        <p class="text-xs text-white font-medium m-0">
                          Applied!
                        </p>
                      </div>
                      <!-- Expired -->
                      <div
                        v-else-if="
                          determineOrientationStatus(training) == 'Expired'
                        "
                        class="bg-red-500 w-20 rounded-md mt-2 mr-3 p-2 flex flex-col items-center"
                      >
                        <img
                          class="w-10 h-10"
                          alt="X, because this Charity Quest user does not have the proper completed Training."
                          src="../assets/navicons/CQ-Navicon-X-White.png"
                        />
                        <p class="text-xs text-white font-medium m-0">
                          Expired!
                        </p>
                      </div>
                      <!-- Failed, Withdrawn, or Never Started -->
                      <div
                        v-else
                        class="bg-red-500 w-20 rounded-md mt-2 mr-3 p-2 flex flex-col items-center"
                      >
                        <img
                          class="w-10 h-10"
                          alt="X, because this Charity Quest user does not have the proper completed Training."
                          src="../assets/navicons/CQ-Navicon-X-White.png"
                        />
                        <p class="text-xs text-white font-medium m-0">
                          Incomplete
                        </p>
                      </div>
                    </div>
                    <!-- The Right, Details -->
                    <div>
                      <h3 class="m-0">{{ training.name }}</h3>
                      <p class="m-0">{{ training.description }}</p>
                      <p class="m-0">
                        <b>Estimated Time:</b>
                        {{
                          prettifyTrainingEstimatedTime(training.estimatedTime)
                        }}
                      </p>
                      <p class="m-0">
                        <b>Estimated Cost:</b>
                        {{
                          prettifyTrainingEstimatedCost(training.estimatedCost)
                        }}
                      </p>
                      <div
                        v-if="determineOrientationStatus(training) == 'Expired'"
                      >
                        <p class="text-red-500 font-bold">
                          Your training for this module expired on
                          {{
                            determineOrientationExpirationDate(
                              training
                            ).toLocaleString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric"
                            })
                          }}. Please apply for re-training.
                        </p>
                      </div>
                      <div
                        v-else-if="
                          determineOrientationStatus(training) ==
                            'Interested' ||
                            determineOrientationStatus(training) ==
                              'Provisional'
                        "
                      >
                        <p class="text-cq-orange font-bold">
                          You applied for this training on
                          {{
                            determineOrientationStartDate(
                              training
                            ).toLocaleString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric"
                            })
                          }}.
                        </p>
                      </div>
                      <div
                        v-else-if="
                          determineOrientationStatus(training) == 'Complete'
                        "
                      >
                        <p class="text-green-500 font-bold">
                          You completed this training on
                          {{
                            determineOrientationResolutionDate(
                              training
                            ).toLocaleString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric"
                            })
                          }}.
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- The Button -->
                  <div
                    v-if="requiredOrientationsWeCouldApplyFor.length == 1"
                    class="flex flex-col items-center"
                  >
                    <button
                      class="bg-indigo-400 hover:bg-indigo-500 cursor-pointer text-white font-bold my-5 py-3 px-4 rounded border-none"
                      v-on:click="
                        showAreYouSureYouWantToApplyForTrainingModal()
                      "
                    >
                      Apply for Required Training "{{
                        requiredOrientationsWeCouldApplyFor[0].name
                      }}"
                    </button>
                  </div>
                  <div
                    v-else-if="requiredOrientationsWeCouldApplyFor.length > 1"
                    class="flex flex-col items-center"
                  >
                    <button
                      class="bg-indigo-400 hover:bg-indigo-500 cursor-pointer text-white font-bold my-5 py-3 px-4 rounded border-none"
                      v-on:click="
                        showAreYouSureYouWantToApplyForTrainingModal()
                      "
                    >
                      Apply for
                      {{ requiredOrientationsWeCouldApplyFor.length }} Required
                      Trainings
                    </button>
                  </div>
                </div>
              </div>

              <!-- OPTION 7, "TOO YOUNG" (You're below the Age Range) -->
              <p
                v-else-if="
                  chariteerIsTooYoungEvenWithSupervision && !chariteerIsManager
                "
                class="text-center"
              >
                You are too young to volunteer for this Quest, even with Adult
                Supervision.
              </p>

              <!-- OPTION 8, "TOO OLD" (You're above the Age Range, you probably need training) -->
              <p
                v-else-if="chariteerIsTooOld && !chariteerIsManager"
                class="text-center pb-5"
              >
                {{ chariteerIsTooOldDynamicText }}
              </p>

              <!-- OPTION 9: "APPLICATION" (This is a Hybrid Role!) --->
              <button
                class="questDetails-signUpButton"
                v-else-if="databaseQuest?.quest?.eventType == 'HYBRID-ROLE'"
                v-on:click="openHybridRoleApplicationIfWeHaveOne"
              >
                Apply for this Hybrid Role
              </button>

              <!-- OPTION 10: "FEAST" -->
              <div
                v-else-if="databaseQuest?.quest?.eventType == 'SUPPLY-REQUEST'"
              >
                <button
                  class="questDetails-signUpButton"
                  v-on:click="handleOpenFeastModal"
                >
                  Fulfill this Supply Request!
                </button>
              </div>

              <!-- OPTION 11: "SIGN UP" (Kids that need supervision) --->
              <button
                class="questDetails-signUpButton"
                v-else-if="
                  chariteerRequiresAdultSupervision && !chariteerIsManager
                "
                v-on:click="determineSignUpToVolunteerWarningToShow"
              >
                Volunteer (With Adult Supervision)
              </button>

              <!-- OPTION 12: "SIGN UP" (Quest is not full, and you're not on this Quest) --->
              <button
                class="questDetails-signUpButton"
                v-else
                v-on:click="determineSignUpToVolunteerWarningToShow"
              >
                Volunteer for this Quest!
              </button>
            </template>
          </div>

          <!-- SURVEYS -->
          <!-- If we have any surveys & results attached to this Quest, let's display that here: -->
          <template v-if="combinedSurveyResultAndQuestions != null">
            <div class="bg-cq-orange-light rounded drop-shadow-xl">
              <h3 class="pt-8 text-center">
                Feast Donation Form
              </h3>
              <h4 class="text-center">
                {{
                  "Form submitted by " +
                    theSiftedContractArray[0].chariteer.nameFirst +
                    " " +
                    theSiftedContractArray[0].chariteer.nameLast +
                    " at " +
                    combinedSurveyResultAndQuestions.submissionTimestamp
                }}
              </h4>
              <ul class="px-4">
                <li
                  class="list-none"
                  v-for="question in combinedSurveyResultAndQuestions.answers"
                  :key="question.questionID"
                >
                  <i>{{ question.question }}</i>
                  <br />
                  {{ "\“" + question.answer + '"' }}
                  <br />
                  <br />
                </li>
              </ul>
            </div>
          </template>

          <!--
            TO DO:
          <h4>Volunteers that Dropped Out:</h4>
          <p>{{ foobar }}</p>
          Similar list to above, except use a new computed array.
          -->
        </div>
        <!--
        <router-link class="text-[#5553F3]" :to="questListUrl">
          <img :src="LeftArrowIcon" alt="up" />
          Quest List
        </router-link>
        -->
      </div>
    </div>

    <!-- MODALS -->
    <!-- "Are You Sure You Want To Volunteer?" (Modal) -->
    <div
      class="are-you-sure-you-want-to-volunteer-modal"
      v-if="
        areYouSureYouWantToVolunteerModalIsVisible &&
          databaseQuest &&
          databaseQuest.quest
      "
    >
      <!-- Modal content -->
      <div
        class="bg-white rounded-lg w-64 w-2/3 z-90 mx-auto top-1/4 relative py-5 px-5"
      >
        <h1 class="text-center">Ready to volunteer?</h1>
        <p class="text-center">
          You're about to commit to volunteer for the Quest
          <span
            ><b>{{ databaseQuest.quest.eventTitle }}</b></span
          >
          and {{ databaseQuest.quest.provider.name }} will be counting on you to
          be at {{ databaseQuest.quest.location.name }} promptly at
          {{ questStartTimeDisplay }} to {{ questEndTimeDisplay }} on
          {{ questDayDisplay }}.
        </p>
        <p
          v-if="chariteerRequiresAdultSupervision"
          class="text-center text-red-500"
        >
          Since you are under the age of
          <span
            ><b>{{ this.questSoloAge }}</b></span
          >, this charity requires you to be supervised by an adult at all
          times. By clicking the sign up button, you agree that you understand
          this and will not arrive alone or be "dropped off" at this Quest.
        </p>
        <p v-else class="text-center">Are you sure?</p>

        <!-- The buttons -->
        <div class="mx-auto grid grid-cols-2 space-x-10">
          <button
            class="bg-red-500 hover:bg-red-400 text-white font-bold py-4 px-4 rounded border border-red-100 hover:border-transparent"
            v-on:click="hideAreYouSureYouWantToVolunteerModal"
          >
            No, I'm not sure...
          </button>
          <button
            class="bg-green-500 hover:bg-green-400 text-white font-bold py-4 px-4 rounded border border-green-100 hover:border-transparent"
            v-on:click="signUpToVolunteer"
          >
            Yes, sign me up!
          </button>
        </div>
      </div>
    </div>

    <!-- "Are You Sure You Want To Apply For These Trainings?" (Modal) -->
    <div
      class="are-you-sure-you-want-to-volunteer-modal"
      v-if="
        areYouSureYouWantToApplyForTrainingModalIsVisible &&
          databaseQuest &&
          databaseQuest.quest
      "
    >
      <!-- Modal content -->
      <div
        class="bg-white rounded-lg w-64 w-2/3 z-90 mx-auto mt-5 relative py-5 px-5"
      >
        <h1 class="text-center">Ready to apply for Training?</h1>
        <p class="text-center">
          You're about to apply for the following Training modules:
        </p>
        <!-- An Orientation Row-->
        <div
          v-for="training in requiredOrientationsWeCouldApplyFor"
          :key="training.id"
          class="bg-gray-100 rounded-md shadow-md p-5 my-5"
        >
          <h3 class="m-0">{{ training.name }}</h3>
          <p class="m-0">{{ training.description }}</p>
          <p class="m-0">
            <b>Estimated Time:</b>
            {{ prettifyTrainingEstimatedTime(training.estimatedTime) }}
          </p>
          <p class="m-0">
            <b>Estimated Cost:</b>
            {{ prettifyTrainingEstimatedCost(training.estimatedCost) }}
          </p>
        </div>

        <p class="text-center">Are you sure?</p>

        <!-- The buttons -->
        <div class="mx-auto grid grid-cols-2 space-x-10">
          <button
            class="bg-red-500 hover:bg-red-400 text-white font-bold py-4 px-4 rounded border border-red-100 hover:border-transparent"
            v-on:click="hideAreYouSureYouWantToApplyForTrainingModal"
          >
            No, I'm not sure...
          </button>
          <button
            class="bg-green-500 hover:bg-green-400 text-white font-bold py-4 px-4 rounded border border-green-100 hover:border-transparent"
            v-on:click="applyForAllOrientationsWeHaveNotStartedAlready"
          >
            Yes, I'm applying for Training!
          </button>
        </div>
      </div>
    </div>

    <!-- "More Options For Chariteer" (Modal) -->

    <MoreOptionsForChariteer
      v-if="
        moreOptionsForChariteerModalIsVisible &&
          databaseQuest &&
          databaseQuest.quest
      "
      :handleSubmitChanges="handleSubmitAdvancedChariteerInstructions"
      :close="handleCloseMoreOptionsForChariteerModal"
      :contractData="currentlySelectedContract"
      :questData="databaseQuest.quest"
    />

    <!-- "Secret Guild Code" (Modal) -->
    <SecretGuildCodeModal
      v-if="
        inputSecretGuildCodeModalIsVisible &&
          databaseQuest &&
          databaseQuest.quest
      "
      :handleTryCode="handleSubmitSecretGuildCode"
      :close="handleCloseSecretGuildCodeModal"
      :groupData="raidingGuildName"
    />

    <!-- "Edit Flex Time" (Modal) -->
    <EditFlexTimesModal
      v-if="
        editFlexTimeVolunteerModalIsVisible &&
          databaseQuest &&
          databaseQuest.quest
      "
      :handleSignUp="handleSubmitFlexTimes"
      :close="handleCloseEditFlexTimeModal"
      :questData="databaseQuest.quest"
    />

    <!-- "Quest Sign Up Confirmation" (Modal) -->
    <QuestSignUpConfirmationModal
      v-if="
        signUpConfirmationModalIsVisible && databaseQuest && databaseQuest.quest
      "
      :close="handleCloseSignUpConfirmationModal"
      :questData="databaseQuest.quest"
      :contractData="currentContract"
    />

    <!-- "Login" (Modal)-->
    <LoginModal v-if="loginModalIsVisible" :close="handleCloseLoginModal" />

    <!-- "Donate Feast" (Modal) -->
    <DonateFeastModal
      v-if="donateFeastModalIsVisible"
      :close="handleCloseFeastModal"
      :signUpToVolunteer="signUpFeast"
      :chariteerId="$store.state.playerProfile.masterPlayerID"
    />

    <!-- "Edit Quest" (Modal) -->
    <EditQuestModal
      v-if="editQuestModalIsVisible"
      :handleSubmitChanges="handleSubmitQuestUpdate"
      :close="handleCloseEditQuestModal"
      :questData="databaseQuest.quest"
    />

    <!-- "Edit Chariteer Alert" (Modal) -->
    <EditChariteerAlertModal
      v-if="editChariteerAlertModalIsVisible"
      :handleSubmitAlert="handleSubmitChariteerAlert"
      :close="handleCloseEditChariteerAlertModal"
      :questData="databaseQuest.quest"
    />

    <Footer />
  </div>
</template>

<script>
// QuestDetails shows you everything about the Quest.
// Intended to be accessed via QuestList OR your ActiveQuests.
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";
import IconPicker from "@/components/IconPicker.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import gql from "graphql-tag";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import {
  properMedallionColor,
  isChariteerAllowedToPerformStaticAction,
  formatPrettyHours,
  TAILWIND_HEX
} from "@/helpers/index.js";
import { getCreateOrientationErrorText } from "@/helpers/errorTextGenerators.js";
import EditFlexTimesModal from "@/components/modals/EditFlexTimesModal.vue";
import QuestSignUpConfirmationModal from "@/components/modals/QuestSignUpConfirmationModal.vue";
import SecretGuildCodeModal from "@/components/modals/SecretGuildCodeModal.vue";
import MoreOptionsForChariteer from "@/components/modals/MoreOptionsForChariteer.vue";
import { formatAMPM } from "../helpers";
import {
  siftVolunteerContracts,
  siftGuildContracts,
  siftVolunteerOrientations
} from "@/helpers/index.js";
import { useCookies } from "vue3-cookies";
import LoginModal from "@/components/modals/LoginModal.vue";
import DonateFeastModal from "@/components/modals/DonateFeastModal.vue";
import EditQuestModal from "../components/modals/EditQuestModal.vue";
import EditChariteerAlertModal from "../components/modals/EditChariteerAlertModal.vue";
import LeftArrowIcon from "../assets/icons/Arrow_Left.png";

export default {
  name: "OSVQuestDetails",
  data() {
    return {
      statedArrivalTime: null,
      statedDepartureTime: null,
      areYouSureYouWantToVolunteerModalIsVisible: false,
      areYouSureYouWantToApplyForTrainingModalIsVisible: false,
      editFlexTimeVolunteerModalIsVisible: false,
      inputSecretGuildCodeModalIsVisible: false,
      moreOptionsForChariteerModalIsVisible: false,
      signUpConfirmationModalIsVisible: false,
      loginModalIsVisible: false,
      donateFeastModalIsVisible: false,
      editQuestModalIsVisible: false,
      editChariteerAlertModalIsVisible: false,
      batchAttendanceArray: [],
      formJSON: null,
      currentlySelectedContract: null,
      LeftArrowIcon
    };
  },
  components: {
    TopNav,
    Footer,
    IconPicker,
    ProgressCircle,
    EditFlexTimesModal,
    QuestSignUpConfirmationModal,
    SecretGuildCodeModal,
    LoginModal,
    MoreOptionsForChariteer,
    DonateFeastModal,
    EditQuestModal,
    EditChariteerAlertModal
  },
  setup() {
    console.log("We're in setup.");
    const notifyError = text => {
      toast.error(text, {
        autoClose: false
      }); // ToastOptions
    };

    const notifySuccess = text => {
      toast.success(text, {
        autoClose: 10000
      }); // ToastOptions
    };

    const route = useRoute();
    const { result, loading, error, refetch } = useQuery(gql`
         query GetQuestById {
           quest (id: ${route.params.id}) {
             id
             providerId
             provider {
              name
              agePolicies
             }
             eventTitle
             eventType
             description
             agePolicyIndex
             publishingStatus
             giverName
             serviceTags
             startedAt
             endedAt
             flexibleMinimumTime
             locationId
             location {
               id
               name
               address
               detail
               latitude
               longitude
             }
             resourceMaxNumber
             prerequisiteMinutes
             requiredOrientations {
                templateId
                name
                description
                ageThreshold
                estimatedTime
                estimatedCost
             }
             pledgedContracts {
               id
               chariteerId
               chariteer {
                id
                username
                nameFirst
                nameLast
                phoneNumber
                emailAddress
                dateOfBirth
               }
               claimedAt
               abandonedAt
               expectedArrivalAt
               expectedDepartureAt
               guild {
                id
                name
                description
                easyJoinCode
               }
               guildId
               status
               formJSON
             }
           }
         }
       `);
    console.log("Refetching Quest!");
    console.log(result);
    const { cookies } = new useCookies();

    // get quest list url
    const questListUrl = `${route.fullPath.split("/quest/")[0]}`;

    return {
      databaseQuest: result || null,
      loading,
      error,
      refetch,
      id: route.params.id,
      notifyError,
      notifySuccess,
      cookies,
      questListUrl
    };
  },
  created() {},
  methods: {
    checkAttendance(item, checkString) {
      if (item.status == checkString) {
        return true;
      } else {
        return false;
      }
    },
    markAttendance(item, newMarkedAttendance) {
      // Before altering this array, we should check if any identical contracts are here:
      let i = 0;
      for (i; i < this.batchAttendanceArray.length; i++) {
        if (this.batchAttendanceArray[i].id == item.id) {
          this.batchAttendanceArray.splice(i, 1);
        }
      }

      console.log(
        "Let's mark " +
          item.chariteer.nameFirst +
          " " +
          item.chariteer.nameLast +
          " as " +
          newMarkedAttendance +
          "."
      );

      // Awkard. Because I think we want to create a JObject array for the mutation
      // But we also need to update the button's display, right?
      console.log(
        "Adding Contract ID " +
          item.id +
          " (" +
          item.chariteer.nameFirst +
          ") to the JObject as " +
          newMarkedAttendance
      );
      let newAttendanceObj = {
        updateAction: "EDIT-ATTENDANCE",
        id: item.id,
        status: newMarkedAttendance,
        supervisorId: this.$store.state.playerProfile.masterPlayerID
      };

      // Arrivals and Departures
      if (newMarkedAttendance == "Absent") {
        // When you're completely Absent, your "actual" arrival times are null, or "never".
        newAttendanceObj.actualArrivalAt = null;
        newAttendanceObj.actualDepartureAt = null;
      } else {
        // With no other information to go on, we assume this volunteer was exactly on time and stayed for the full amount they promised,
        // regardless of the flexibility settings of this Quest.
        // TO DO - figure out a UI for recording more detailed attendance, which is open to all staff regardless of the Quest's flexibility.
        // That's how you would say "this kid was 30 minutes late!" or "wow, she stayed an extra hour to help!"
        newAttendanceObj.actualArrivalAt = item.expectedArrivalAt;
        newAttendanceObj.actualDepartureAt = item.expectedDepartureAt;
      }

      this.batchAttendanceArray.push(newAttendanceObj);
      console.log("Batch Attendance Array is now:");
      console.log(this.batchAttendanceArray);

      return;
    },
    checkNewAttendanceArray(item, checkString) {
      let i = 0;
      for (i; i < this.batchAttendanceArray.length; i++) {
        if (this.batchAttendanceArray[i].id == item.id) {
          // OK, this Contract is in the Batch Attendance Array. We intend to edit it.
          // ... true or false, is it CHECKSTRING?
          if (this.batchAttendanceArray[i].status == checkString) {
            console.log(
              "True, " +
                item.chariteer.nameFirst +
                " " +
                item.chariteer.nameLast +
                " (" +
                item.id +
                ") is going to be saved as " +
                checkString +
                "!"
            );
            return true;
          } else {
            console.log(
              "False, " +
                item.chariteer.nameFirst +
                " " +
                item.chariteer.nameLast +
                " (" +
                item.id +
                ") is going to be saved as " +
                item.status +
                "..."
            );
            return false;
          }
        }
      }
    },
    calculatedAgeFromDOB(inputDOB) {
      var age = -1;
      var today = new Date();
      var birthDate = new Date(inputDOB);
      age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      // Is this person a minor?
      if (age < 18) {
        return age + " - Minor";
      }

      return age;
    },
    async signUpToVolunteer() {
      // console.log("Chariteer clicked the Sign Up button!");
      this.hideAreYouSureYouWantToVolunteerModal();

      // Tracking
      // var tagsObj = this.$store.state.currentlyViewedQuest
      // tagsObj.messageType = "Chariteer Clicks Volunteer Button"
      // this.$store.dispatch('clickTrackLogForAmazon', tagsObj)

      // Execution
      const result = await this.mutationSignUpToVolunteer();

      if (result.errors?.length > 0) {
        const errorMessage = result.errors[0].extensions.response.message;
        const errorCode = result.errors[0].extensions.response.statusCode;

        // Missing Items
        if (errorCode == 404 && errorMessage == "Quest Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Provider Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Contract Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        // Logical Issues
        else if (
          errorCode == 406 &&
          errorMessage == "Chariteer Is Already On This Quest"
        )
          this.notifyError(
            "ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!"
          );
        else if (errorCode == 406 && errorMessage == "Quest Is Full")
          this.notifyError(
            "ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!"
          );
        else if (
          errorCode == 410 &&
          errorMessage == "Quest Already Over, Can't Sign Up"
        )
          this.notifyError(
            "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!"
          );
        else
          this.notifyError(
            "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
          );
      } else {
        // UI and Data Refresh
        this.refetch();

        // Let's try to make money from this
        // do we have our currentContract yet???
        this.signUpConfirmationModalIsVisible = true;

        // Toast
        this.notifySuccess("You're signed up, friend!");
      }

      // TO DO
      // USE STALE TIME TO SEE IF WE SHOULD REFRESH THE PAGE B/C THE
      // USER WAS AWAY "COOKING SPAGHETTI"
    },
    async mutationSignUpToVolunteer() {
      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
            mutation CreateContract ($CCI: CreateContractInput!) {
              createContract(createContractInput: $CCI) {
                id,
                chariteerId,
                quest {
                  id
                  eventTitle
                  pledgedContracts {
                    id
                    questId
                    chariteerId
                    abandonedAt
                  }
                },
                questId
                claimedAt
                abandonedAt
                abandonReason
                formJSON
              }
            }
          `,
          variables: {
            CCI: {
              chariteerId: this.$store.state.playerProfile.masterPlayerID,
              questId: parseInt(this.id),
              expectedArrivalAt: this.statedArrivalTime,
              expectedDepartureAt: this.statedDepartureTime,
              formJSON: this?.formJSON
            }
          }
        })
      });

      const data = await result.json();
      return data;
    },
    async signUpFeast(form) {
      const stringJson = JSON.stringify(form);
      this.formJSON = stringJson;
      this.hideFeastModal();
      const result = await this.signUpToVolunteer();
      return result;
    },
    async bailOutOfQuest() {
      // console.log("Chariteer clicked the Bail Out button!")

      // Tracking
      // var tagsObj = this.$store.state.currentlyViewedQuest
      // tagsObj.messageType = "Chariteer Clicks Bail Button"
      // this.$store.dispatch('clickTrackLogForAmazon', tagsObj)

      // Before we bail we need to make sure our Contract ID is set properly.
      // If it's null, we shouldn't call this GraphQL.
      if (this.currentContractID) {
        // Execution
        const result = await this.mutationBailOut();

        if (result.errors?.length > 0) {
          // console.log("Displaying Notification...");
          const errorMessage = result.errors[0].extensions.response.message;
          const errorCode = result.errors[0].extensions.response.statusCode;

          // Missing Items
          if (errorCode == 404 && errorMessage == "Quest Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          else if (errorCode == 404 && errorMessage == "Provider Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          else if (errorCode == 404 && errorMessage == "Contract Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          // Logical Issues
          else if (
            errorCode == 410 &&
            errorMessage == "Chariteer Not Signed Up, Can't Abandon"
          )
            this.notifyError(
              "ERROR! This is a glitch, you're not signed up for this Quest. For some reason, the system is still letting you bail out. Sorry about that!"
            );
          else if (
            errorCode == 410 &&
            errorMessage == "Quest Already Over, Can't Abandon"
          )
            this.notifyError(
              "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you drop out even though it ended. Sorry about that!"
            );
          else
            this.notifyError(
              "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
            );
        } else {
          // UI and Data Refresh
          this.refetch();

          // Toast
          this.notifySuccess("You abandoned this Quest.");
        }
      }
    },
    async mutationBailOut() {
      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
            mutation AbandonContract ($UCI: UpdateContractInput!) {
              abandonContract(updateContractInput: $UCI) {
                id
                claimedAt
                abandonReason
                abandonedAt
              }
            }
          `,
          variables: {
            UCI: {
              id: parseInt(this.currentContractID)
            }
          }
        })
      });

      const data = await result.json();
      return data;
    },
    async saveAttendanceRecordsToDatabase() {
      console.log("Calling the mutation...");

      // This is a good place to generate the "JObject updateArrayObject" from Unity

      // Execution
      const result = await this.mutationSaveAttendanceRecords();

      if (result.errors?.length > 0) {
        console.log("We have errors...");
        let errorMessage = "";

        if (result.errors[0].extensions?.response?.message != undefined)
          errorMessage = result.errors[0].extensions.response.message;
        else errorMessage = result.errors[0].message;

        console.log("ERROR: " + errorMessage);
        let errorCode = "GRAPHQL";

        if (result.errors[0].extensions?.response?.statusCode != undefined) {
          errorCode = result.errors[0].extensions.response.statusCode;
          console.log("CODE: " + errorCode);
        } else {
          errorCode = result.errors[0].extensions.code;
        }

        // Missing Items
        if (errorCode == 404 && errorMessage == "Quest Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Provider Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Contract Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == "BAD_USER_INPUT") this.notifyError(errorMessage);
        else
          this.notifyError(
            "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
          );
      } else {
        // UI and Data Refresh
        this.refetch();

        // Toast
        this.notifySuccess("Attendance saved!");

        // Flush that array
        console.log("Batch Attendance array should get destroyed now");
      }
    },
    async mutationSaveAttendanceRecords() {
      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
            mutation UpdateContracts ($UCI: [UpdateContractInput!]!) {
              updateContracts(data: $UCI ) {
                contract {
                  id
                  questId
                  quest {
                    id
                    eventTitle
                    publishingStatus
                    pledgedContracts {
                      id
                      questId
                      chariteer {
                        username
                        nameFirst
                        nameLast
                        emailAddress
                        phoneNumber
                        dateOfBirth
                      }
                      chariteerId
                      guild {
                        id
                        name
                        description
                        easyJoinCode
                      }
                      guildId
                      claimedAt
                      abandonReason
                      abandonedAt
                      expectedArrivalAt
                      expectedDepartureAt
                      actualArrivalAt
                      actualDepartureAt
                      formJSON
                    }
                  }
                  chariteerId
                  claimedAt
                  abandonReason
                  abandonedAt
                  expectedArrivalAt
                  expectedDepartureAt
                  status
                  actualArrivalAt
                  actualDepartureAt
                  formJSON
                }
                message
              }
            }
          `,
          variables: {
            UCI: this.batchAttendanceArray
          }
        })
      });

      const data = await result.json();
      return data;
    },
    async mutationBoot(changesObject) {
      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
            mutation BootSomeone($UCI: UpdateContractInput!) {
              updateContract(updateContractInput: $UCI) {
                id
                claimedAt
                abandonReason
                abandonedAt
              }
            }
          `,
          variables: {
            UCI: {
              id: parseInt(changesObject.contractId),
              updateAction: changesObject.option,
              supervisorId: changesObject.supervisorId,
              abandonReason: changesObject.bootMessage
            }
          }
        })
      });

      const data = await result.json();
      return data;
    },
    determineSignUpToVolunteerWarningToShow() {
      // Is this a Flex Quest? Are we showing them a "pick your time" window?
      // Or is this a regular Quest, and we're just double checking that they want to volunteer?
      // console.log("Pop up!")
      // console.log("Flexible Minimum Time is " + this.databaseQuest.quest.flexibleMinimumTime);

      if (
        this.databaseQuest.quest.flexibleMinimumTime == undefined ||
        this.databaseQuest.quest.flexibleMinimumTime == 0
      ) {
        // Both of these values are for normal, non-flexible Quests
        console.log("Showing a ARE YOU SURE pop-up");
        this.statedArrivalTime = this.databaseQuest.quest.startedAt;
        this.statedDepartureTime = this.databaseQuest.quest.endedAt;
        this.showAreYouSureYouWantToVolunteerModal();
      } else {
        // Stated arrival and departure times chosen by a pop-up, these are defaults.
        console.log("Choose your Start and End times");
        this.statedArrivalTime = this.databaseQuest.quest.startedAt;
        this.statedDepartureTime = this.databaseQuest.quest.endedAt;
        this.editFlexTimeVolunteerModalIsVisible = true;
      }
    },
    showAreYouSureYouWantToVolunteerModal() {
      console.log("Are You Sure Modal should be visible now.");
      this.areYouSureYouWantToVolunteerModalIsVisible = true;
    },
    hideAreYouSureYouWantToVolunteerModal() {
      console.log("Are You Sure Modal should be invisible now.");
      this.areYouSureYouWantToVolunteerModalIsVisible = false;
    },
    showAreYouSureYouWantToApplyForTrainingModal() {
      console.log("Apply For Training Modal should be visible now.");
      this.areYouSureYouWantToApplyForTrainingModalIsVisible = true;
    },
    hideAreYouSureYouWantToApplyForTrainingModal() {
      console.log("Apply For Training Modal should be invisible now.");
      this.areYouSureYouWantToApplyForTrainingModalIsVisible = false;
    },
    hideFeastModal() {
      this.donateFeastModalIsVisible = false;
    },
    handleCloseSignUpConfirmationModal() {
      console.log("Closing!");
      this.signUpConfirmationModalIsVisible = false;
    },
    handleSubmitFlexTimes(arrival, departure) {
      this.editFlexTimeVolunteerModalIsVisible = false;

      this.statedArrivalTime = arrival;
      this.statedDepartureTime = departure;

      this.signUpToVolunteer();
    },
    handleCloseEditFlexTimeModal() {
      this.editFlexTimeVolunteerModalIsVisible = false;
    },
    showGuildCodePanel() {
      console.log("Showing Secret Guild Code panel.");
      this.inputSecretGuildCodeModalIsVisible = true;
    },
    handleCloseSecretGuildCodeModal() {
      console.log("Closing Secret Guild Code panel.");
      this.inputSecretGuildCodeModalIsVisible = false;
    },
    handleSubmitSecretGuildCode(code) {
      // Codes any length other than 4 characters are not attempted
      if (code.length == 4) {
        // query? do we need to query? we know the code...
        // the query you would actually call here is sign up + join group
        console.log("Attempting passcode " + code);

        if (code == this.raidingGuildSecretCode) {
          this.notifySuccess("Guild Code Accepted, please wait...");
          this.handleCloseSecretGuildCodeModal();
          this.handleChariteerJoinsGroupViaSecretGuildCode();
        } else {
          this.notifyError(
            "Sorry, that is not the correct code for this Guild."
          );
          console.log("We're looking for " + this.raidingGuildSecretCode);
        }
      }
    },
    async handleChariteerJoinsGroupViaSecretGuildCode() {
      // we're going to submit the player's title ID and the GROUP ID
      // then, refetch. You can't just add someone to a Quest. What if it's a flex quest?

      console.log(
        "This player has this title id: " +
          this.$store.state.playerProfile.entityID
      );
      console.log(
        "This player has this title id: " +
          this.$store.state.playerProfile.entityType
      );

      // Execution
      const result = await this.mutationJoinGroupViaSecretGuildCode();

      if (result.errors?.length > 0) {
        console.log(result);
        const errorMessage = result.errors[0].extensions.response.message;
        const errorCode = result.errors[0].extensions.response.statusCode;

        // Missing Items
        if (errorCode == 404 && errorMessage == "Quest Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Provider Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Contract Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        // Logical Issues
        else if (
          errorCode == 406 &&
          errorMessage == "Chariteer Is Already On This Quest"
        )
          this.notifyError(
            "ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!"
          );
        else if (errorCode == 406 && errorMessage == "Quest Is Full")
          this.notifyError(
            "ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!"
          );
        else if (
          errorCode == 410 &&
          errorMessage == "Quest Already Over, Can't Sign Up"
        )
          this.notifyError(
            "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!"
          );
        else
          this.notifyError(
            "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
          );
      } else {
        // Let's make sure this Guild is listed in groupMemberships, come Hell or High Water.
        const refreshedGuildData = await this.$store.dispatch(
          "downloadPlayerGroupMemberships"
        );

        // UI and Data Refresh
        this.refetch();

        // Toast
        this.notifySuccess(
          "You have successfully joined your Guild! Now you can sign up for reserved Quests."
        );
      }
    },
    async mutationJoinGroupViaSecretGuildCode() {
      console.log(
        "Adding this user to " + this.raidingGuildContract?.guild?.id
      );

      let effectiveGuildID = "";
      console.log(this.raidingGuildContract);

      if (this.raidingGuildContract?.guild?.id) {
        effectiveGuildID = this.raidingGuildContract.guild.id;
      } else if (
        this.databaseQuest.quest?.prerequisiteMinutes &&
        this.databaseQuest.quest?.providerId == "7F6860372DB1C8E0"
      ) {
        effectiveGuildID = "510B1E3D1F7B3190";
      } else {
        effectiveGuildID = null;
        console.log("This call will never succeed!");
        return;
      }

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
            mutation AddChariteerToGuild ($ASCI: AddSingleChariteerInput!) {
              addChariteerToGuild(addSingleChariteer: $ASCI) {
                id
              }
            }
          `,
          variables: {
            ASCI: {
              id: this.$store.state.playerProfile.masterPlayerID,
              playFabTitleId: this.$store.state.playerProfile.entityID,
              guildId: effectiveGuildID
            }
          }
        })
      });

      const data = await result.json();
      return data;
    },
    showMoreOptionsForSpecificChariteerModal(chariteer, e, contract) {
      console.log("OSV CONTRACT", contract);
      console.log("Should we report or boot " + chariteer.nameFirst + "?");
      this.moreOptionsForChariteerModalIsVisible = true;
      this.currentlySelectedContract = contract;
    },
    handleCloseMoreOptionsForChariteerModal() {
      console.log("We're done with this person.");
      this.moreOptionsForChariteerModalIsVisible = false;
      this.currentlySelectedContract = null;
    },
    async handleSubmitAdvancedChariteerInstructions(changesObject) {
      console.log("HURP");
      console.log(changesObject);
      const option = changesObject.option;
      switch (option) {
        case "BOOT":
          const result = await this.mutationBoot(changesObject);
          console.log("RESULT", result);
          if (result.errors?.length > 0) {
            console.log("Displaying Notification...");
            const errorMessage = result.errors[0].extensions.response.message;
            console.log("ERROR: " + errorMessage);
            this.notifyError(errorMessage);
          } else {
            this.refetch();
            this.notifySuccess("You booted this Chariteer.");
          }
          break;
        default:
          console.log("No option selected.");
          break;
      }
      this.moreOptionsForChariteerModalIsVisible = false;
    },
    openHybridRoleApplicationIfWeHaveOne() {
      if (this.databaseQuest?.quest?.serviceTags?.length > 1) {
        let volunteerApplicationLink = this.databaseQuest?.quest
          ?.serviceTags[1];
        window.open(volunteerApplicationLink, "_blank", "noreferrer");
      } else console.log("Nothing to open, sorry.");
    },
    returnToQuestList() {
      this.$router.push("/questList");
    },
    openSponsorWebsite() {
      var tagsObj = {
        messageType: "Chariteer Clicks Sponsor's Banner In Quest"
      };
      this.$store.dispatch("clickTrackLogForAmazon", tagsObj);

      // Now I'll send people to GiveSendGo
      window.open("https://www.givesendgo.com/charity_quest");
    },
    flexArrivalString(contract) {
      // don't we have a computed isQuestFlexible boolean?
      if (
        this.databaseQuest.quest.flexibleMinimumTime == undefined ||
        this.databaseQuest.quest.flexibleMinimumTime == 0
      ) {
        return "";
      } else {
        var arrival = new Date(contract.expectedArrivalAt);
        var departure = new Date(contract.expectedDepartureAt);

        return (
          "(" +
          arrival.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          }) +
          " to " +
          departure.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          }) +
          ")"
        );
      }

      return "";
    },
    goToLogin() {
      this.cookies.set(
        "returnTo",
        { path: this.$route.path, query: this.$route.query },
        { expires: 1 }
      );
      this.loginModalIsVisible = true;
      return null;
    },
    handleCloseLoginModal() {
      this.loginModalIsVisible = false;
    },
    goToSignUp() {
      this.cookies.set(
        "returnTo",
        { path: this.$route.path, query: this.$route.query },
        { expires: 1 }
      );
      this.$router.push("/createChariteerAccount");
      return null;
    },
    handleOpenFeastModal() {
      this.donateFeastModalIsVisible = true;
    },
    handleCloseFeastModal() {
      this.donateFeastModalIsVisible = false;
    },
    handleOpenEditQuestModal() {
      this.editQuestModalIsVisible = true;
    },
    handleCloseEditQuestModal() {
      this.editQuestModalIsVisible = false;
    },
    async handleSubmitQuestUpdate(changesObject) {
      console.log("Do we have some changes here?");
      console.log(changesObject);
      this.editQuestModalIsVisible = false;

      // Some error checking I guess
      if (changesObject != undefined && changesObject?.id != undefined) {
        console.log("A quest is being updated!");

        // Execution
        const result = await this.mutationUpdateQuest(changesObject);

        if (result.errors?.length > 0) {
          const errorMessage = result.errors[0].extensions.response.message;
          const errorCode = result.errors[0].extensions.response.statusCode;

          // Missing Items
          if (errorCode == 404 && errorMessage == "Quest Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          else if (errorCode == 404 && errorMessage == "Provider Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          else if (errorCode == 404 && errorMessage == "Contract Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          // Logical Issues
          //
          //  REVIEW THESE
          // AND CONSIDER MOVING THIS TO A UTILITY.
          //
          else if (
            errorCode == 406 &&
            errorMessage == "Chariteer Is Already On This Quest"
          )
            this.notifyError(
              "ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!"
            );
          else if (errorCode == 406 && errorMessage == "Quest Is Full")
            this.notifyError(
              "ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!"
            );
          else if (
            errorCode == 410 &&
            errorMessage == "Quest Already Over, Can't Sign Up"
          )
            this.notifyError(
              "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!"
            );
          else
            this.notifyError(
              "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
            );
        } else {
          // UI and Data Refresh
          this.refetch();

          // Toast
          this.notifySuccess("Quest has been modified.");
        }
      } else {
        console.log("There are no changes!");
      }
    },
    async mutationUpdateQuest(changes) {
      // Make some changes to a Quest
      console.log("User wants to update the Quest (" + changes.id + ")");
      console.log("The new Supervisor will be " + changes.giverName);
      console.log(
        "The new Publishing Status will be " + changes.publishingStatus
      );
      console.log("The new volunteer max will be " + changes.resourceMaxNumber);
      console.log("The new description will be " + changes.description);
      console.log("The new starting time will be " + changes.startedAt);
      console.log("The new ending time will be " + changes.endedAt);

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
              mutation UpdateQuest ($UQI: UpdateQuestInput!) {
                updateQuest(updateQuestInput: $UQI) {
                  id
                  providerId
                  eventTitle
                  eventType
                  publishingStatus
                  description
                  agePolicyIndex
                  serviceTags
                  startedAt
                  endedAt
                  flexibleMinimumTime
                  resourceMaxNumber
                  locationId
                }
              }
            `,
          variables: {
            UQI: {
              id: changes.id,
              giverName: changes.giverName,
              publishingStatus: changes.publishingStatus,
              resourceMaxNumber: changes.resourceMaxNumber,
              description: changes.description,
              startedAt: changes.startedAt,
              endedAt: changes.endedAt
            }
          }
        })
      });

      const data = await result.json();
      return data;
    },
    handleOpenEditChariteerAlertModal() {
      this.editChariteerAlertModalIsVisible = true;
    },
    handleCloseEditChariteerAlertModal() {
      this.editChariteerAlertModalIsVisible = false;
    },
    prettifyTrainingEstimatedTime(number) {
      const prettyHours = formatPrettyHours(number);
      return prettyHours;
    },
    prettifyTrainingEstimatedCost(number) {
      let dollars = number / 100;
      return (
        "$ " + dollars.toLocaleString("en-US", { minimumFractionDigits: 2 })
      );
    },
    async applyForAllOrientationsWeHaveNotStartedAlready() {
      console.log("We're going to apply for these trainings:");
      console.log(this.requiredOrientationsWeCouldApplyFor);

      // Let's run the function...
      const result = await this.mutationCreateMultipleOrientations();

      console.log("Wrapping up here...");

      // Close the confirmation modal when we're done.
      this.hideAreYouSureYouWantToApplyForTrainingModal();

      if (result.errors?.length > 0) {
        const errorMessage = result.errors[0].message;
        const errorCode = result.errors[0].extensions.code;

        // GET ORIENTATION ERROR FOR TOAST
        let errorText = getCreateOrientationErrorText(errorCode, errorMessage);

        this.notifyError(errorText);
      } else {
        // UI and Data Refresh
        this.refetch();

        // Toast
        this.notifySuccess("You applied for Training!");
      }

      return;
    },
    async mutationCreateMultipleOrientations() {
      console.log("Beginning mutation.");
      // const orientations = this.requiredOrientationsWeCouldApplyFor;
      const orientationsArray = {
        input: { orientations: this.requiredOrientationsWeCouldApplyFor }
      };
      console.log(orientationsArray);

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
              mutation CreateMultipleOrientations ($input: CreateMultipleOrientationsInput!) {
                createMultipleOrientations(input: $input) {
                  id
                  providerId
                  templateId
                  name
                  description
                  startedAt
                  resolution
                  resolvedAt
                  expiredAt
                  url
                  note
                }
              }
            `,
          variables: {
            input: orientationsArray.input
          }
        })
      });

      const data = await result.json();
      return data;
    },
    determineOrientationStatus(training) {
      // Does this Chariteer have a record related to this particular Orientation?
      // If so, what is the status of that row? Have they begun an Orientation, did
      // they fail it, or perhaps has it expired?

      console.log(
        "We're determining the Orientation status of " +
          training.name +
          " which has Template Id " +
          training.templateId
      );
      var theTemplateWeNeedToMatch = training.templateId;

      var matchingTrainings = this.theSiftedOrientationArray.filter(
        orientation => orientation.templateId === theTemplateWeNeedToMatch
      );

      // If the array is empty, we never even tried to apply for this Training before!
      if (matchingTrainings.length == 0) {
        return "Incomplete";
      }

      // OK, it seems like we have tried to apply for this Training. How did it go?
      const recentMatchingTraining = matchingTrainings.sort(
        (a, b) => b.id - a.id
      )[0];
      if (
        recentMatchingTraining.resolution == "Complete" &&
        recentMatchingTraining.expiredAt != null
      ) {
        // Has our Completed training passed its expiration date?
        let todaysDate = new Date();
        let expirationDate = new Date(recentMatchingTraining.expiredAt);
        if (todaysDate > expirationDate) {
          if (i == matchingTrainings.length - 1) {
            // We're at the end of the line, sooo....
            return "Expired";
          }
        }
      }
      return recentMatchingTraining.resolution;
    },
    determineOrientationExpirationDate(training) {
      // For this training we have been given, when did it expire?
      console.log(
        "We're determining the Expiration Date of " +
          training.name +
          " which has Template Id " +
          training.templateId
      );
      var theTemplateWeNeedToMatch = training.templateId;

      var matchingTrainings = this.theSiftedOrientationArray.filter(
        orientation => orientation.templateId === theTemplateWeNeedToMatch
      );
      console.log(matchingTrainings);

      // Now, there could be many of these. But we need to find the latest one that has expired.
      let i = 0;
      let latestExpiredTrainingDate = null;

      for (i; i < matchingTrainings.length; i++) {
        if (matchingTrainings[i].expiredAt != undefined) {
          let tempDate = new Date(matchingTrainings[i].expiredAt);

          // Let's check this...
          if (latestExpiredTrainingDate == null) {
            // Obviously we need to replace the null, so just call this one the latest for now.
            latestExpiredTrainingDate = tempDate;
          } else if (latestExpiredTrainingDate < tempDate) {
            console.log(
              "New latest training, " +
                tempDate +
                " was after " +
                latestExpiredTrainingDate
            );
            latestExpiredTrainingDate = tempDate;
          } else {
            // Don't bother updating this, we still have the latest one.
            console.log(
              "Keeping latest training, " +
                tempDate +
                " was before " +
                latestExpiredTrainingDate
            );
          }
        }
        // Go to next loop!
      }

      if (latestExpiredTrainingDate == null) {
        return "No expired trainings found.";
      } else return latestExpiredTrainingDate;
    },
    determineOrientationStartDate(training) {
      // For this training we have been given, when did it start?
      console.log(
        "We're determining the Start Date of " +
          training.name +
          " which has Template Id " +
          training.templateId
      );
      var theTemplateWeNeedToMatch = training.templateId;

      var matchingTrainings = this.theSiftedOrientationArray.filter(
        orientation => orientation.templateId === theTemplateWeNeedToMatch
      );
      console.log(matchingTrainings);

      // Now, there could be many of these. But we need to find the one with the latest start date.
      let i = 0;
      let latestStartedTrainingDate = null;

      for (i; i < matchingTrainings.length; i++) {
        if (matchingTrainings[i].startedAt != undefined) {
          let tempDate = new Date(matchingTrainings[i].startedAt);

          // Let's check this...
          if (latestStartedTrainingDate == null) {
            // Obviously we need to replace the null, so just call this one the latest for now.
            latestStartedTrainingDate = tempDate;
          } else if (latestStartedTrainingDate < tempDate) {
            console.log(
              "New latest training, " +
                tempDate +
                " was after " +
                latestStartedTrainingDate
            );
            latestStartedTrainingDate = tempDate;
          } else {
            // Don't bother updating this, we still have the latest one.
            console.log(
              "Keeping latest training, " +
                tempDate +
                " was before " +
                latestStartedTrainingDate
            );
          }
        }
        // Go to next loop!
      }

      if (latestStartedTrainingDate == null) {
        return "No started trainings found.";
      } else return latestStartedTrainingDate;
    },
    determineOrientationResolutionDate(training) {
      // For this training we have been given, when was it Resolved?
      console.log(
        "We're determining the Resolution Date of " +
          training.name +
          " which has Template Id " +
          training.templateId
      );
      var theTemplateWeNeedToMatch = training.templateId;

      var matchingTrainings = this.theSiftedOrientationArray.filter(
        orientation => orientation.templateId === theTemplateWeNeedToMatch
      );
      console.log(matchingTrainings);

      // Now, there could be many of these. But we need to find the one with the latest resolution date.
      let i = 0;
      let latestResolvedTrainingDate = null;

      for (i; i < matchingTrainings.length; i++) {
        if (matchingTrainings[i].resolvedAt != undefined) {
          let tempDate = new Date(matchingTrainings[i].resolvedAt);

          // Let's check this...
          if (latestResolvedTrainingDate == null) {
            // Obviously we need to replace the null, so just call this one the latest for now.
            latestResolvedTrainingDate = tempDate;
          } else if (latestResolvedTrainingDate < tempDate) {
            console.log(
              "New latest training, " +
                tempDate +
                " was after " +
                latestResolvedTrainingDate
            );
            latestResolvedTrainingDate = tempDate;
          } else {
            // Don't bother updating this, we still have the latest one.
            console.log(
              "Keeping latest training, " +
                tempDate +
                " was before " +
                latestResolvedTrainingDate
            );
          }
        }
        // Go to next loop!
      }

      if (latestResolvedTrainingDate == null) {
        return "No resolved trainings found.";
      } else return latestResolvedTrainingDate;
    }
  },
  computed: {
    getHexForMedallion() {
      if (
        this.databaseQuest &&
        this.databaseQuest.quest.serviceTags &&
        this.databaseQuest.quest.serviceTags.length > 0
      ) {
        const correctString = properMedallionColor(
          this.databaseQuest.quest.serviceTags[0]
        );

        return TAILWIND_HEX[correctString];
      } else {
        return "#000000";
      }
    },
    questDayDisplay() {
      var date = new Date(this.databaseQuest.quest.startedAt);

      return date.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    questStartTimeDisplay() {
      var startTime = new Date(this.databaseQuest.quest.startedAt);
      var startTimeText = startTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });

      return startTimeText.toLowerCase();
    },
    questEndTimeDisplay() {
      // Supply Requests don't have an End Time...
      if (this.databaseQuest.quest.endedAt == null) {
        return null;
      } else {
        var endTime = new Date(this.databaseQuest.quest.endedAt);
        var endTimeText = endTime.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true
        });

        return endTimeText.toLowerCase();
      }
    },
    totalPledgedContracts() {
      // Was cleaning up code and found this. What is this for? It's not used anywhere.
      // Was this supposed to be for seeing who bailed out on the Quest?

      var contracts = 0;

      if (this.databaseQuest) {
        if (this.databaseQuest.quest.pledgedContracts) {
          contracts = this.databaseQuest.quest.pledgedContracts.length;
        } else {
          contracts = 0;
        }
      } else {
        // console.log("Couldn't get Pledged Contracts for some reason, so saying Zero.");
        contracts = 0;
      }

      return contracts;
    },
    theSiftedContractArray() {
      return siftVolunteerContracts(this.databaseQuest.quest.pledgedContracts);
    },
    theSiftedOrientationArray() {
      if (
        this.$store.state.playerProfile?.chariteer?.startedOrientations ==
          undefined ||
        this.$store.state.playerProfile?.chariteer?.startedOrientations
          .length == 0
      ) {
        return [];
      } else
        return siftVolunteerOrientations(
          this.$store.state.playerProfile?.chariteer?.startedOrientations,
          this.databaseQuest?.quest?.providerId
        );
    },
    questAgePolicyObject() {
      if (
        this.databaseQuest.quest.agePolicyIndex == undefined ||
        this.databaseQuest.quest.agePolicyIndex == null ||
        this.databaseQuest.quest.provider.agePolicies == undefined
      ) {
        return null;
      } else {
        var agePolicyObject = JSON.parse(
          this.databaseQuest.quest.provider.agePolicies
        );

        return agePolicyObject;
      }
    },
    questAdultSupervisionPolicyText() {
      var theText = null;

      // It's awkward when the Adult Supervision Age (17) is one less than the Solo Age (18).
      // We used to end up with text like "Volunteers between the ages of 17 and 17 can sign up if they are accompanied by an adult at all times."
      // So let's check for that condition first...
      const oneLessThanTheSoloAge = this.questSoloAge - 1;

      if (this.questAdultSupervisionAge == oneLessThanTheSoloAge) {
        theText =
          "Volunteers who are " +
          this.questAdultSupervisionAge +
          " years old can sign up if they are accompanied by an adult at all times.";
      } else if (this.questAdultSupervisionAge < this.questSoloAge) {
        theText =
          "Volunteers between the ages of " +
          this.questAdultSupervisionAge +
          " and " +
          oneLessThanTheSoloAge +
          " can sign up if they are accompanied by an adult at all times.";
      }

      return theText;
    },
    chariteerIsTooOld() {
      if (this.questAgePolicyObject == null) {
        return false;
      } else if (this.chariteerIsFullyTrained) {
        // If we have completed all the Training, bypass age rules... for now.
        // This is dumb, I realize that!
        return false;
      } else {
        console.log(
          "Are we too old? We're " +
            this.$store.state.playerProfile.age +
            " and the Max Age is " +
            this.questMaxAge
        );

        if (this.$store.state.playerProfile.age >= this.questMaxAge) {
          console.log(
            this.$store.state.playerProfile.age +
              " is too old, maybe they need training."
          );
          return true;
        }
      }

      console.log(this.$store.state.playerProfile.age + " is not too old.");
      return false;
    },
    chariteerIsTooOldDynamicText() {
      var displayString =
        "Volunteers over the age of " +
        this.questMaxAge +
        " need special permission to sign up for this Quest. Training will be available in-app eventually, but for now please email me at frank@charityquest.io and I can connect you to the volunteer coordinator at this charity!";
      return displayString;
    },
    chariteerIsTooYoungEvenWithSupervision() {
      if (this.questAgePolicyObject == null) {
        return false;
      } else {
        if (
          this.$store.state.playerProfile.age < this.questAdultSupervisionAge
        ) {
          return true;
        }
      }
      return false;
    },
    chariteerRequiresAdultSupervision() {
      if (this.questAgePolicyObject == null) {
        return false;
      } else {
        if (
          this.$store.state.playerProfile.age >=
            this.questAdultSupervisionAge &&
          this.$store.state.playerProfile.age < this.questSoloAge
        ) {
          return true;
        }
      }

      return false;
    },
    questSoloAge() {
      if (this.questAgePolicyObject == null) {
        return 1;
      } else {
        return this.questAgePolicyObject.agePolicies[
          this.databaseQuest.quest.agePolicyIndex
        ].soloAge;
      }
    },
    questMaxAge() {
      if (this.questAgePolicyObject == null) {
        // console.log("Setting Max Age to 100 or something.");
        return 100;
      } else {
        return this.questAgePolicyObject.agePolicies[
          this.databaseQuest.quest.agePolicyIndex
        ].maxAge;
      }
    },
    questAdultSupervisionAge() {
      if (this.questAgePolicyObject == null) {
        // console.log("Setting Supervised Age to 1 or something.");
        return 1;
      } else {
        return this.questAgePolicyObject.agePolicies[
          this.databaseQuest.quest.agePolicyIndex
        ].supervisedAge;
      }
    },
    chariteerIsManager() {
      // Hack, for time. If this Provider ID is one of the ManagedProviderIds in your managedProviderGroupMemberships, yer a wizard Harry.
      var i = 0;
      for (
        i = 0;
        i < this.$store.state.managedProviderGroupMemberships.length;
        i++
      ) {
        if (
          this.$store.state.managedProviderGroupMemberships[i]
            .ManagedProviderId == this.databaseQuest.quest.providerId
        ) {
          // console.log("User is a Manager.");
          return true;
        }
      }

      // Otherwise, return FALSE.
      return false;
    },
    managedProviderGroupDataObject() {
      // Let's search for the Membership Data we care about.
      let i = 0;
      let groupMembershipData = null;
      console.log("The jamm");

      for (
        i;
        i < this.$store.state.managedProviderGroupMemberships.length;
        i++
      ) {
        if (
          this.$store.state.managedProviderGroupMemberships[i]
            .ManagedProviderId == this.databaseQuest.quest.providerId
        ) {
          console.log(
            "We found data for Group " +
              this.$store.state.managedProviderGroupMemberships[i]
                .ManagedProviderName
          );
          groupMembershipData = this.$store.state
            .managedProviderGroupMemberships[i];
        }
      }

      if (groupMembershipData == null) {
        console.log("We couldn't find any membership data object.");
        return null;
      }

      console.log(
        "In the Mgmt Group for " +
          groupMembershipData.ManagedProviderName +
          " (" +
          this.databaseQuest.quest.providerId +
          "), user's Roles are: "
      );
      for (i = 0; i < groupMembershipData.Roles.length; i++) {
        console.log(
          groupMembershipData.Roles[i].RoleName +
            " / " +
            groupMembershipData.Roles[i].RoleId
        );
      }

      return groupMembershipData;
    },
    chariteerCanReadNames() {
      return (
        this.chariteerCanEditQuests ||
        isChariteerAllowedToPerformStaticAction(
          this.managedProviderGroupDataObject,
          "memberFullNames",
          "R",
          "read memberFullNames"
        )
      );
    },
    chariteerCanReadContactInfo() {
      return (
        this.chariteerCanEditQuests ||
        isChariteerAllowedToPerformStaticAction(
          this.managedProviderGroupDataObject,
          "memberContactInfo",
          "R",
          "read memberContactInfo"
        )
      );
    },
    chariteerCanTakeAttendance() {
      return (
        this.chariteerCanEditQuests ||
        isChariteerAllowedToPerformStaticAction(
          this.managedProviderGroupDataObject,
          "chariteerAttendance",
          "U",
          "update attendance"
        )
      );
    },
    chariteerCanEditQuests() {
      const userIsAllowedToEditQuests = isChariteerAllowedToPerformStaticAction(
        this.managedProviderGroupDataObject,
        "quest",
        "U",
        "edit Quests"
      );
      return userIsAllowedToEditQuests;
    },
    chariteerCanSendQuestAlerts() {
      const userIsAllowedToSendQuestAlerts = isChariteerAllowedToPerformStaticAction(
        this.managedProviderGroupDataObject,
        "chariteerAlert",
        "C",
        "send Quest alerts"
      );
      return userIsAllowedToSendQuestAlerts;
    },
    chariteerIsOnThisQuest() {
      var i = 0;

      for (i = 0; i < this.theSiftedContractArray.length; i++) {
        if (
          this.$store.state.playerProfile.masterPlayerID ==
          this.theSiftedContractArray[i].chariteerId
        ) {
          // console.log("We got a match, we're on this Quest!");

          return true;
        }
      }
      return false;
    },
    chariteerIsNotExperiencedEnoughWithThisCharity() {
      // The purpose of this determination is to see three things...
      // (a) if this Quest has a prerequisite, which is rare
      // (b) if we're Managers and therefore bypass that requirement
      // (c) if we meet the prerequisite based on our volunteer experience

      var volunteerGoal = "";
      var volunteerProgress = "";

      if (this.databaseQuest.quest?.prerequisiteMinutes) {
        volunteerGoal = Math.floor(
          this.databaseQuest.quest?.prerequisiteMinutes / 60
        );
        volunteerProgress = Math.floor(
          this.$store.state.totalProviderMinutesDictionary[
            this.databaseQuest.quest.providerId
          ] / 60
        );

        console.log(
          "This Quest requires " +
            volunteerGoal +
            " hour(s) of volunteering. So far, " +
            volunteerProgress +
            " have been completed."
        );

        if (volunteerProgress < volunteerGoal) {
          // You are not experienced enough, true!
          console.log(
            "This chariteer needs to volunteer more in order to do this Quest."
          );
          return true;
        } else if (isNaN(volunteerProgress)) {
          console.log("Treating " + volunteerProgress + " like zero.");
          return true;
        } else {
          // You are experienced enough!
          console.log("You are PREPARED!");
          return false;
        }
      } else {
        // Prerequisites is NULL. Most Quests are like this. You're qualified by default!
        console.log("There's no prerequisite hours required for this Quest.");
        return false;
      }
    },
    chariteerIsFullyTrained() {
      // Most quests don't require any training, so usually you are "fully trained".
      console.log("Number of required trainings:");
      console.log(this.databaseQuest.quest?.requiredOrientations?.length);

      // First, an easy one: if there's no Required Orientations, you're good to go.
      if (this.databaseQuest.quest?.requiredOrientations?.length == 0) {
        console.log("We are fully trained!");
        return true;
      }
      // Next, since there is training to do, if you're younger than the minimum age
      // required to do this training, you can just skip it...
      else if (
        this.databaseQuest.quest?.requiredOrientations.ageThreshold >
        this.$store.state.playerProfile.age
      ) {
        console.log("We're young enough to not be threatening...");
        return true;
      } else if (this.missingRequiredOrientations.length == 0) {
        // After analyzing the Required Orientations, and your progress so far, we believe you are
        // good to go! You are truly fully trained.
        return true;
      } else {
        // You have some missing required orientations, soo.....
        console.log("We are not fully trained...");
        return false;
      }
    },
    questIsFull() {
      if (
        this.theSiftedContractArray.length >=
        this.databaseQuest.quest.resourceMaxNumber
      ) {
        // console.log("This Quest is full.");
        return true;
      } else {
        // console.log("This Quest has at least 1 open spot.");
        return false;
      }
    },
    questIsOver() {
      if (this.databaseQuest?.quest?.endedAt != null) {
        var endTime = new Date(this.databaseQuest.quest.endedAt);
      } else if (this.databaseQuest?.quest?.eventType == "HYBRID-ROLE") {
        var endTime = new Date();
        endTime.setDate(endTime.getDate() + 7);
      } else {
        // Not sure what else to do here!
        var endTime = new Date(this.databaseQuest.quest.startedAt);
      }

      var now = new Date();

      if (endTime <= now) {
        // console.log("This Quest is over.");
        return true;
      } else {
        // console.log("This Quest is in the future.");
        return false;
      }
    },
    slotsRemaining() {
      var slots = 0;

      if (
        this.databaseQuest &&
        this.databaseQuest.quest &&
        this.databaseQuest.quest.resourceMaxNumber
      ) {
        slots =
          this.databaseQuest.quest.resourceMaxNumber -
          this.theSiftedContractArray.length;

        if (slots < 0) slots = 0;
        console.log(this.databaseQuest); //todo: remove
        console.log("There should be " + slots + " remaining.");
      }

      return slots;
    },
    questDescription() {
      return this.databaseQuest.quest.description;
    },
    currentContractID() {
      var i = 0;
      var contractID = null;

      for (i = 0; i < this.theSiftedContractArray.length; i++) {
        if (
          this.$store.state.playerProfile.masterPlayerID ==
          this.theSiftedContractArray[i].chariteerId
        ) {
          contractID = this.theSiftedContractArray[i].id;
          return contractID;
        }
      }

      return contractID;
    },
    currentContract() {
      var i = 0;
      var contract = null;

      for (i = 0; i < this.theSiftedContractArray.length; i++) {
        if (
          this.$store.state.playerProfile.masterPlayerID ==
          this.theSiftedContractArray[i].chariteerId
        ) {
          console.log("Found our Contract.");
          contract = this.theSiftedContractArray[i];
          return contract;
        }
      }

      return contract;
    },
    firstServiceTag() {
      if (
        this.databaseQuest.quest.serviceTags &&
        this.databaseQuest.quest.serviceTags.length > 0
      ) {
        return this.databaseQuest.quest.serviceTags[0];
      } else {
        return "";
      }
    },
    raidingGuildContract() {
      var siftedArray = siftGuildContracts(
        this.databaseQuest.quest.pledgedContracts
      );

      if (siftedArray.length == 1) {
        console.log("We have a Raid!");
        return siftedArray[0];
      } else if (siftedArray.length > 1) {
        // Panic, at the disco
        console.log("We have more than one Raid...?");
        // I panicked... but then, I handled it!
        return siftedArray[0];
      } else {
        console.log("No Raiding Guild");
        return null;
      }
    },
    raidingGuildName() {
      if (this.raidingGuildContract != null) {
        console.log("We have a Guild Name!");
        return this.raidingGuildContract.guild.name;
      } else if (
        this.databaseQuest.quest?.prerequisiteMinutes &&
        this.databaseQuest.quest?.providerId == "7F6860372DB1C8E0"
      ) {
        return "St. Mary Advocates";
      } else {
        // Not sure what's going on!
        console.log("No Raiding Guild");
        return "Unknown Guild";
      }
    },
    raidingGuildSecretCode() {
      if (this.raidingGuildContract != null) {
        console.log("We have a Guild Code!");
        return this.raidingGuildContract.guild.easyJoinCode;
      } else if (
        this.databaseQuest.quest?.prerequisiteMinutes &&
        this.databaseQuest.quest?.providerId == "7F6860372DB1C8E0"
      ) {
        return "1976";
      } else {
        return "NO CODE";
      }
    },
    questIsClaimedByRivalGroup() {
      // Does this Quest have any active Guild Contracts?

      if (this.raidingGuildContract != null) {
        // Let's examine this raid... are we in this raiding group?
        console.log("We have at least 1 Ongoing Raid!");
        var i = 0;

        for (i = 0; i < this.$store.state.groupMemberships.length; i++) {
          // If we find a match, return false because this is not a Rival Group!
          if (
            this.$store.state.groupMemberships[i].Group.Id ==
            this.raidingGuildContract.guildId
          ) {
            return false;
          }
        }

        // No match found. This is a Rival Guild!
        return true;
      } else {
        // Impossible, there are no Ongoing Raids!
        return false;
      }
    },
    questIsClaimedByYourGroup() {
      // Does this Quest have any active Guild Contracts?
      if (this.raidingGuildContract != null) {
        // Let's examine this raid... are we in this raiding group?
        console.log("We have at least 1 Ongoing Raid!");
        var i = 0;

        for (i = 0; i < this.$store.state.groupMemberships.length; i++) {
          // If we find a match, return true because this is one of ours!
          if (
            this.$store.state.groupMemberships[i].Group.Id ==
            this.raidingGuildContract.guildId
          ) {
            return true;
          }
        }

        // No match found. This is a Rival Guild!
        return false;
      } else {
        return false;
      }
    },
    combinedSurveyResultAndQuestions() {
      // We're expecting just 1 active contract to have a form filled out because Supply Requests
      // are maxed at one person. So most of the time, this will be null.
      if (
        this.databaseQuest.quest.eventType == "SUPPLY-REQUEST" &&
        this.theSiftedContractArray != undefined &&
        this.theSiftedContractArray.length > 0
      ) {
        const obj = JSON.parse(this.theSiftedContractArray[0].formJSON);

        // HARDCODE
        obj.answers[0].question =
          "Please describe the meal you are preparing in detail (with the amounts included) so the Kitchen Manager knows what to expect and we can assess if it will be enough food.";
        obj.answers[1].question =
          "Please confirm that you are bringing enough to feed 100 people. (If it's not enough, you can always drop it off, but please don't submit this form)";
        obj.answers[2].question =
          "Are you bringing cooked food that just needs to be heated up, or are you planning on cooking the entire meal from scratch in our kitchen?";
        obj.answers[3].question =
          "Our kitchen has limited space. Please limit the number of kitchen helpers to a maximum of two (not including yourself) and write their names in the box below.";
        obj.answers[4].question =
          "If this is the first time you're making a big donation like this, or if it's been a while, call the Shelter Manager Rev. Anthony Forbes at (201) 620-4792 immediately after submitting this form to go over more details.";
        // HARDCODE
        return obj;
      } else {
        // console.log("This is either not a Supply Request, or it hasn't been claimed yet.");
        return null;
      }

      // If we somehow ended up here, return null just to be safe.
      return null;
    },
    progressFillPercentage() {
      var volunteerGoal = "";
      var volunteerProgress = "";

      if (this.databaseQuest.quest?.prerequisiteMinutes) {
        volunteerGoal =
          "" + Math.floor(this.databaseQuest.quest?.prerequisiteMinutes / 60);
        volunteerProgress = this.$store.state.totalProviderMinutesDictionary[
          this.databaseQuest.quest.providerId
        ];

        if (volunteerProgress == undefined || volunteerProgress == null) {
          return 0;
        } else {
          volunteerProgress = "" + Math.floor(volunteerProgress / 60);
          return (volunteerProgress / volunteerGoal) * 100;
        }
      }

      return 0;
    },
    progressCircleText() {
      var volunteerGoal = "";
      var volunteerProgress = "";

      if (this.databaseQuest.quest?.prerequisiteMinutes) {
        volunteerGoal =
          "" + Math.floor(this.databaseQuest.quest?.prerequisiteMinutes / 60);
        volunteerProgress = this.$store.state.totalProviderMinutesDictionary[
          this.databaseQuest.quest.providerId
        ];

        console.log(
          "This chariteer completed " +
            volunteerProgress +
            " minutes with " +
            this.databaseQuest.quest.provider.name +
            "."
        );

        if (volunteerProgress == undefined || volunteerProgress == null) {
          volunteerProgress = "0";
        } else {
          volunteerProgress = "" + Math.floor(volunteerProgress / 60);
        }

        return volunteerProgress + " / " + volunteerGoal;
      }

      return "";
    },
    missingRequiredOrientations() {
      // If this quest has required Orientations, we need to compare to YOUR completed / started orientations
      // and give you a number here. Or a list, actually. That's better. The point is, we need to know how many
      // orientations the player is missing. This list is inclusive, so started-yet-incomplete trainings like
      // those that are Interested / Provisional are in the same list as ones that are Failed / Withdrawn / Expired.

      // If this quest doesn't have any, just return an empty list.
      if (this.databaseQuest.quest?.requiredOrientations?.length == 0) {
        console.log("No missing trainings.");
        return [];
      } else {
        // We have a function that handles this for us, so here's the drill: for each training that this quest
        // requires, if we don't get a return value of "Complete" for EACH ONE, then the player can't volunteer.

        let i = 0;
        let missingTrainings = [];
        for (
          i;
          i < this.databaseQuest.quest?.requiredOrientations?.length;
          i++
        ) {
          let response = this.determineOrientationStatus(
            this.databaseQuest.quest?.requiredOrientations[i]
          );

          if (response != "Complete" && response != "Provisional") {
            missingTrainings.push(
              this.databaseQuest.quest?.requiredOrientations[i]
            );
          }
        }

        console.log(
          "We have " + missingTrainings.length + " missing trainings!"
        );
        console.log(missingTrainings);
        return missingTrainings;
      }
    },
    requiredOrientationsWeCouldApplyFor() {
      // Give us a list of the Orientations we haven't even applied for yet. This will
      // be used for the query, so it should not include things we already started.
      // Double records would be a nightmare!

      // If this quest doesn't have any, just return an empty list.
      if (this.databaseQuest.quest?.requiredOrientations?.length == 0) {
        console.log("We shouldn't apply for anything!");
        return [];
      } else {
        // We have a function that handles this for us, so here's the drill: for each training that this quest
        // requires, if we don't get a return value of "Complete", "Interested", or "Provisional" for EACH ONE,
        // then the player should be able to apply for them.
        // We're allowing reapplication for Expired, Failed, and Withdrawn trainings.

        let i = 0;
        let orientationsWeCouldApplyFor = [];
        for (
          i;
          i < this.databaseQuest.quest?.requiredOrientations?.length;
          i++
        ) {
          let response = this.determineOrientationStatus(
            this.databaseQuest.quest?.requiredOrientations[i]
          );

          if (
            response == "Complete" ||
            response == "Interested" ||
            response == "Provisional"
          ) {
            console.log(
              "No need to apply for " +
                this.databaseQuest.quest?.requiredOrientations[i].name +
                " again."
            );
          } else {
            const {
              __typename,
              ...orientation
            } = this.databaseQuest.quest?.requiredOrientations[i];
            orientationsWeCouldApplyFor.push({
              ...orientation,
              chariteerId: this.$store.state.playerProfile.masterPlayerID,
              providerId: this.databaseQuest.quest.providerId
            });
          }
        }

        console.log(
          "We could apply for " +
            orientationsWeCouldApplyFor.length +
            " trainings!"
        );
        return orientationsWeCouldApplyFor;
      }
    }
  }
};
</script>

<style scoped>
#page-container {
  min-height: 100vh;
}

.questDetails {
  border-radius: 5px;
  width: 500px;
  padding: 0px;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
}

.special-class {
  text-align: left;
}

.questDescription {
  text-align: left;
  white-space: pre-wrap;
}

.questDetailsFlexbox {
  background-color: #00000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 0px auto;
}

.questDetailsVolunteerList {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 20px;
  box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.25);
}

.questDetailsVolunteerList h3 {
  text-align: center;
}

.questDetailsVolunteerListOther {
  color: white;
  text-align: left;
  background-color: #ffa94e9d;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 0px;
  padding: 10px;
}

.questDetailsVolunteerListPlayer {
  text-align: left;
  background-color: #ffa94e;
}

.questDetailsVolunteerListForAdmins {
  text-align: left;
  background-color: #4eff9e;
}

.questDetailsVolunteerListEmpty {
  color: white;
  background-color: #bbbbbb9d;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 0px;
  padding: 10px;
}

.questDetailsFlexbox p {
  background-color: #00000000;
}

.questDetails-signUpButton {
  background-color: #22c913;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px 50px;
  outline: none;

  color: #ffffff;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}

.questDetails-signUpButton:hover {
  background-color: #23da13;
}

.questDetails-signUpButton:active {
  background-color: #20a513;
  transform: scale(0.95);
}

.questDetails-bailOutButton {
  background-color: #e44444;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px 50px;
  outline: none;

  color: #ffffff;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}

.questDetails-bailOutButton:hover {
  background-color: #f55353;
}

.questDetails-bailOutButton:active {
  background-color: #b82323;
  transform: scale(0.95);
}

/* Sponsors */
.heroSponsorButton {
  background-color: rgba(0, 0, 0, 0);
  border-width: 0px;
  margin: 0px;
  margin-bottom: 40px;
  padding: 0px;
  cursor: pointer;
}

#content-wrap {
  padding-top: 50px;
  padding-bottom: 100px; /* Footer height */
}

.backgroundTEST {
  background-color: #cacaca !important;
}

/* MODALS */
.are-you-sure-you-want-to-volunteer-modal {
  /* display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
}

.questDetails-logInButton {
  background-color: #ffa94e;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px 45px;
  outline: none;

  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}
.questDetails-logInButton:hover {
  background-color: #ffbb6e;
  box-shadow: inset 0 0 10px #f8a100;
}
</style>
